@font-face
  font-family: "Antenna"
  src: url('../fonts/Antenna-Regular.woff2') format('woff2')
  font-weight: normal

*
  margin: 0
  padding: 0

html,
body
  background-color: #282C34
  color: #ABB2BF
  height: 100%

body
  display: grid
  place-content: center
  font-family: "Antenna"
  font-weight: normal
  text-align: center

h1
  color: inherit
  font-size: 3rem
  font-weight: normal

#subtitle
  margin-bottom: 1em

#password
  display: block
  width: 100%
  padding: .5em
  font-size: 1.2em
  font-family: Courier, monospace
  background: inherit
  border: 1px solid #ABB2BF
  border-radius: .5em
  cursor: pointer

  &.ok
    border-color: #98C379

  .letter
    color: #98C379

  .number
    color: #56B6C2

  .symbol
    color: #C678DD

#length-input
  margin-top: 1em
  display: flex
  gap: 1ch

  #length
    text-align: right
    font-variant-numeric: tabular-nums
    width: 2.5ch

  input
    width: 100%
